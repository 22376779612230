// Import Bootstrap JS and CSS
import * as bootstrap from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // ✅ Ensures Bootstrap JS is loaded
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import emailjs from "emailjs-com";

gsap.registerPlugin(ScrollTrigger);

// Fix scroll reset issue when closing the offcanvas menu
document.addEventListener("hidden.bs.offcanvas", (event) => {
  if (event.target.id === "offcanvasNav") {
    const currentScrollPosition = window.scrollY;
    window.scrollTo(0, currentScrollPosition);
  }
});

// Render Breadcrumb Dynamically
function updateBreadcrumb() {
  const breadcrumbContainer = document.querySelector(".breadcrumb");
  const urlParams = new URLSearchParams(window.location.search);
  const categoryName = urlParams.get("category");
  const productName = urlParams.get("product");

  if (breadcrumbContainer) {
    breadcrumbContainer.innerHTML = `
      <a href="./index.html">Home</a>
      ${categoryName ? `<i class="bi bi-chevron-right mx-2"></i><a href="./category.html?category=${encodeURIComponent(categoryName)}">${categoryName}</a>` : ""}
      ${productName ? `<i class="bi bi-chevron-right mx-2"></i><span>${productName}</span>` : ""}
    `;

    // ✅ If product is from Inventory, make sure breadcrumb updates correctly
    if (categoryName === "Inventory") {
      breadcrumbContainer.innerHTML = `
        <a href="./index.html#inventory">Inventory</a>
        ${productName ? `<i class="bi bi-chevron-right mx-2"></i><span>${productName}</span>` : ""}
      `;
    }
  }
}

const productsByCategory = {
  "Vertical Turning Lathes": [
    {
      title: `Double Column Vertical Turning Lathe SHIBAURA TD60/85S CNC FAGOR

`,
      description: `
      Main technical data:

Ready for turning and milling operations

- FAGOR 8055 CNC
- MAX. TURNING DIA. 8500mm
- MAX. TURNING HEIGHT 3500mm
- FACE PLATE DIA.: 6000 mm
- MAX. WEIGHT OF WORKPIECE: 160 to.
- FACE PLATE SPEED RANGE: 0,4-31,5RPM
- MAIN MOTOR POWER 100KW
- C AXIS AND MILLING
- MACHINE WEIGHT AB. 220TON

 

The machine has one grinding and milling attachments.
The machine had a general revision and CNC upgrade with FAGOR 8055 in 2006.
The machine can be inspected under power.
Do not hesitate to contact us for any information.`,
      year: 1979,
      location: "RO",
      images: [
        new URL("../assest/vtl Shibaura  (1).jpg", import.meta.url),
        new URL("../assest/vtl Shibaura  (5).jpg", import.meta.url),
        new URL("../assest/vtl Shibaura  (10).jpg", import.meta.url),
        new URL("../assest/vtl Shibaura  (15).jpg", import.meta.url),
      ],
    },
    {
      title: `Vertical turning lathe SC17 CNC`,
      description: `
Made in Romania by I.M. ROMAN

 

Main technical data:

Faceplate diameter – 1.450 mm
Max turning diamter -1.650 mm
Max Job heigth -1.200 mm
Table speed range -2 -200 rpm
Working feed range – 0,1-400 mm/min
Rapid traverse – 4000 mm/min
Main motor – 55 kw
Weigth -ab. 21 to

 

Do not hesitate to contact us for any other information.
      `,
      year: 1992,
      location: "RO",
      images: [
        new URL("../assest/SC17CNC (4).jpg", import.meta.url),
        new URL("../assest/SC17CNC.jpg", import.meta.url),
        new URL("../assest/SC17CNC (2).jpg", import.meta.url),
        new URL("../assest/SC17CNC (3).jpg", import.meta.url),
      ],
    },
  ],
  Lathes: [
    {
      title: "Heavy duty lathe SKODA SIU 1600X10000 CNC Siemens",
      description: `
      The machine was completely retrofitted and upgraded with CNC in 2002.

Main technical data:
• face plate diameter: 1500mm
• max. swing over bed: 1600mm
• max. swing over slide: 1250mm
• height of centers: 800mm
• distance between centers: 10000mm
• max. weight of the workpiece: 40to
• speed range of the main spindle: 0.45 – 224rpm
• main drive motor: 55KW
• Machine weight: ab. 56700kg

Equipped with:
- Siemens CNC 802
- Haidenhein scales
- 4 steady rest

For more information please contact us.


      `,
      year: 1978,
      location: "RO",
      images: [
        new URL("../assest/siu 1600x10000 (3).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (5).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (8).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (9).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (24).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (1).jpg", import.meta.url),
      ],
    },

    {
      title: "Heavy duty lathe SN1800X8000",
      description: `Main technical data :

•    face plate diameter: 1800mm
•    max. swing over bed: 1780mm
•   max. swing over slide: 1450mm
•   height of centers: 800mm
•   distance between centers: 8000mm
•   max. weight of the workpiece: 16to
•   speed range of the main spindle: 0.8 – 160rpm
•   main drive motor: 55KW (old one revised)
•   longitudinal feed: 0.4 – 480 mm/min
•   transversal feed: 0.2 – 240 mm/min
•   intermediate slide feed: 0.15 – 180 mm/min
•   intermediate slide travel: 500mm
•   cross slide travel: 520mm  
•   tool holder travel: 175mm
•  Machine weight: ab. 31000kg

 

Accessories :

-4 jaw face plate
-3 steady rests
-grinding  attachment
- milling attachment

 
Do not hesitate to contact us for any other information.
Manufacturer: 1MAI PLOIESTI (Romania).`,
      year: 1982,
      location: "RO",
      images: [
        new URL("../assest/sn1800x8000 (1).jpg", import.meta.url),
        new URL("../assest/sn1800x8000 (2).jpg", import.meta.url),
        new URL("../assest/sn1800x8000 (4).jpg", import.meta.url),
        new URL("../assest/sn1800x8000 (5).jpg", import.meta.url),
        new URL("../assest/sn1800x8000.jpg", import.meta.url),
      ],
    },
    {
      title: `Lathe TOS SU50A 500X1000`,
      description: `Main technical data:

Swing over bed: 500 mm
Swing over carriage: 250 mm
Distance between centers: 1000 mm
Spindle bore: 56 mm
Spindle speed – range 11.2-1400 rpm
Working feed – number of degrees 87
Working feed – range 0.027-3.8 mm / min
Total power input of the machine: 11 kW
Lathe weight 3000kg

 

Do not hesitate to contact us for any other information.`,
      year: "-",
      location: "RO",
      images: [
        new URL("../assest/lathe tos 500x1000 (2).jpg", import.meta.url),
        new URL("../assest/lathe tos 500x1000 (3).jpg", import.meta.url),
        new URL("../assest/lathe tos 500x1000 (4).jpg", import.meta.url),
        new URL("../assest/lathe tos 500x1000 (5).jpg", import.meta.url),
        new URL("../assest/lathe tos 500x1000 (8).jpg", import.meta.url),
      ],
    },
  ],
  "Gear Hobbing/Grinding Machines": [
    {
      title: `Gear hobbing machine MODUL ZFWZ 6300x40/1`,
      description: `
      Manufacturer: WMW-MODUL
      Model: ZFWZ 6300x40/1
      
max. wheel dia.: 7100mm
gear width: 1500 mm
max. modul: 45
table diameter: 5300 mm
milling cutter dia. x width: 400x500 mm
max. helical angle: 45 degrees
hob diameter: 500 mm
hob turning speeds: 9-63 U/min
total power requirement: 47 kW
weight of the machine: 160 ton

 

equipped with:
normal hobbing head, tangential hobbing head, internal milling head, change gears, supports.

Do not hesitate to contact us for any other information.`,

      year: 1970,
      location: "RO",
      images: [
        new URL("../assest/zfwz6300 (38).jpg", import.meta.url),
        new URL("../assest/zfwz6300 (1).jpg", import.meta.url),
        new URL("../assest/zfwz6300 (22).jpg", import.meta.url),
        new URL("../assest/zfwz6300 (33).jpg", import.meta.url),
        new URL("../assest/zfwz6300 (19).jpg", import.meta.url),
        new URL("../assest/zfwz6300 (24).jpg", import.meta.url),
      ],
    },
  ],
  "Forging hammer": [
    {
      title: `Forging hammer 3 ton`,
      description: `
Nominal mass of drop parts kg 3150
Max. no. of blows/min. 50
Ram stroke 1250 mm
Height of the working area: 630mm
Distance between columns: 2700mm
Distance between guideways: 630mm
Diameter of the workinh cylinder 760mm
Diameter of the ram rod : 180mm
Surface of the anvil: 330x630mm
Weight of the anvil: 47600kg
Weight of the hammer less the anvil: 26900kg
Total weight of the hammer: 74700kg

 
Do not hesitate to contact us for any information.
      `,
      year: 1970,
      location: "RO",
      images: [
        new URL("../assest/forging hammer 3ton (2).jpg", import.meta.url),
        new URL("../assest/forging hammer 3ton (3).jpg", import.meta.url),
        new URL("../assest/forging hammer 3ton (5).jpg", import.meta.url),
        new URL("../assest/forging hammer 3ton (6).jpg", import.meta.url),
      ],
    },
  ],
  "Plano millers": [
    {
      title: `Plano miller FLP2200X8000`,
      description: `
Made in Romania by TITAN
YOM 1981

Main technical data:
Table size: 2200x8000mm
Travel of the crossrail: 1600mm
Saddle with the milling spindle:
Travel 2600mm
Working feed: 10-3000mm/min
Rapid feed: 3000mm/min
Positioning feed 3 mm/min
Ram:
Travel: 1000mm
Working feed: 10-3000mm/min
Rapid feed: 3000mm/min
Positioning feed 3 mm/min
Distance between columns 2660mm
Machine overall size: 21250x6800x6650mm (Lxwxh)

 

The machine is under power.
Do not hesitate to contact us for any other information.
      `,
      year: 1981,
      location: "RO",
      images: [
        new URL("../assest/flp2200x8000 (2).jpg", import.meta.url),
        new URL("../assest/flp2200x8000 (3).jpg", import.meta.url),
        new URL("../assest/flp2200x8000 (1).jpg", import.meta.url),
        new URL("../assest/flp2200x8000 (4).jpg", import.meta.url),
        new URL("../assest/flp2200x8000 (5).jpg", import.meta.url),
        new URL("../assest/flp2200x8000 (6).jpg", import.meta.url),
      ],
    },
  ],
  "Grinding Machines": [
    {
      title: `Slideway grinding machine Waldrich Coburg model 50-15 S6060 3000X12000`,
      description: `
     
Main technical data:
table size 3000x12000mm
distance between columns 3600mm
grinding length 12000mm
grinding height 3000mm
max. workpiece weight 58000kg

 

Do not hesitate to contact us for any other information.
      `,
      year: 1977,
      location: "RO",
      images: [
        new URL("../assest/waldrich coburg 3000x12000 (4).jpg", import.meta.url),
        new URL("../assest/waldrich coburg 3000x12000 (6).jpg", import.meta.url),
        new URL("../assest/waldrich coburg 3000x12000 (7).jpg", import.meta.url),
        new URL("../assest/waldrich coburg 3000x12000 (10).jpg", import.meta.url),
        new URL("../assest/waldrich coburg 3000x12000 (13).jpg", import.meta.url),
      ],
    },
  ],
  "CNC Lathes": [
    {
      title: "Heavy duty lathe SKODA SIU 1600X10000 CNC Siemens",
      description: `
      The machine was completely retrofitted and upgraded with CNC in 2002.

Main technical data:
• face plate diameter: 1500mm
• max. swing over bed: 1600mm
• max. swing over slide: 1250mm
• height of centers: 800mm
• distance between centers: 10000mm
• max. weight of the workpiece: 40to
• speed range of the main spindle: 0.45 – 224rpm
• main drive motor: 55KW
• Machine weight: ab. 56700kg

Equipped with:
- Siemens CNC 802
- Haidenhein scales
- 4 steady rest

For more information please contact us.


      `,
      year: 1978,
      location: "RO",
      images: [
        new URL("../assest/siu 1600x10000 (3).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (5).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (8).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (9).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (24).jpg", import.meta.url),
        new URL("../assest/siu 1600x10000 (1).jpg", import.meta.url),
      ],
    },
    {
      title: "Twin Spindle CNC Turning Machine Muratec MW120",
      description: `
Made in Japan
Year of manufacture 2002/09
FANUC 18i CNC

Fully automatic twin spindle lathe
High speed 3 axis automated gantry loader
2 x 8 station turrets
Distance between spindles 340mm
Max. swing 295mm
Chuck size 165/205mm
Max. workpiece size guide line:
Slide. 120mm (with loader); 230mm (without loader)
Max. length: 75mm (with loader); 145mm (without loader)
Stroke: X-130mm; Z-145mm
Spindle speed: 45-4500rpm
Total weight: 4400kg
Chip conveyor


The lathe belong to an automated line consisted in Robot FANUC M-6iB + High speed CNC lathe Muratec MS60 + Twin Spindle CNC Turning Machine Muratec MW120.
The lathe is sold by itself or can be sold the complete automatic line. If interested we can send you our offer.
      `,
      year: 2002,
      location: "RO",
      images: [
        new URL("../assest/muratec mw120 (4).jpg", import.meta.url),
        new URL("../assest/muratec mw120 (2).jpg", import.meta.url),
        new URL("../assest/muratec mw120 (1).jpg", import.meta.url),
        new URL("../assest/muratec mw120 (8).jpg", import.meta.url),
      ],
    },
    {
      title: "High speed CNC turning lathe Muratec MS60",
      description: `
Year of manufacture 2002/12
FANUC 18i CNC

Fully automatic single spindle turning lathe
Can be combined with twin spindle CNC turning centers from MW Series MURATEC
High speed 3 axis automated gantry loader
8 station turret
Max. swing: 295mm
Chuck size: 165/205mm
Max. workpiece size guide line:
Slide. 120mm (with loader); 230mm (without loader)
Max. length: 75mm (with loader); 145mm (without loader)
Stroke: X-130mm; Z-145mm
Spindle speed: 45-4500rpm
Total weight: 2500kg
Chip conveyor

The lathe is sold by itself or can be sold together with Twin Spindle CNC Turning Machine Muratec MW120.
The machine is in good working condition. For more details please contact us.
      `,
      year: 2002,
      location: "RO",
      images: [
        new URL("../assest/ms60 (23).jpg", import.meta.url),
        new URL("../assest/ms60 (21).jpg", import.meta.url),
        new URL("../assest/ms60 (14).jpg", import.meta.url),
        new URL("../assest/ms60 (22).jpg", import.meta.url),
        new URL("../assest/ms60 (26).jpg", import.meta.url),
      ],
    },
    {
      title: "MURATEC MT12 TWIN OPPOSING SPINDLES WITH LIVE TOOLS & GANTRY LOADER",
      description: `
Max. swing: 210mm
Chuck size: 215mm
Max. workpiece size guide line: 
Max Dia. 170mm
Max. length: 220mm
Stroke: X- 200mm; Z1-200mm; Z2-650mm
Spindle speed: 4500rpm
Total weight: 5500kg

 

For more information please contact us.
      `,
      year: "-",
      location: "RO",
      images: [
        new URL("../assest/Machine Muratec MT12 (3).jpg", import.meta.url),
        new URL("../assest/Machine Muratec MT12 (7).jpg", import.meta.url),
        new URL("../assest/Machine Muratec MT12 (5).jpg", import.meta.url),
        new URL("../assest/Machine Muratec MT12 (4).jpg", import.meta.url),
        new URL("../assest/Machine Muratec MT12 (6).jpg", import.meta.url),
        new URL("../assest/Machine Muratec MT12 (2).jpg", import.meta.url),
      ],
    },
  ],
  "Floor Plates": [
    {
      title: "Floor plates with T slots",
      description: `

 We offer for following floor plates:
      
1. Floor plates with T slots 3500x1750x350mm – 10pcs – 4500Euro/pc (4000kg/piece weight)
2. Floor plates with T  5000x1400x270mm – 10pcs  - 3500Euro/pc (5500kg/piece)
3. Floor plates with T slots 3500x2000x400mm - 2pcs – 4700Euro/pc (6000kg/piece)
4. Floor plates with T slots 4200x1500x350mm- 5pcs – 4100Euro/pc (5400kg/piece)
5. Floor plates with T slots 4500x1500x350mm- 2pcs – 4500Euro/pc (6300kg/piece)
The prices are understood FCA-ROMANIA, loaded in container.


For more information please contact us.
      `,
      year: "-",
      location: "RO",
      images: [
        new URL("../assest/floor plates 3500x2000x400mm (4).jpeg", import.meta.url),
        new URL("../assest/floor plates 3500x2000x400mm (5).jpg", import.meta.url),
        new URL("../assest/floor plates 3500x2000x400mm (6).jpg", import.meta.url),
        new URL("../assest/floor plates 3500x2000x400mm (7).jpg", import.meta.url),
        new URL("../assest/floor plates 3500x2000x400mm (8).jpg", import.meta.url),
      ],
    },
  ],
};

// Render Categories for Desktop and Mobile
function renderCategories() {
  const desktopMenuFirstColumn = document.querySelector(".desktop-categories .d-flex > div:first-child ul");
  const desktopMenuSecondColumn = document.querySelector(".desktop-categories .d-flex > div:last-child ul");
  const mobileMenu = document.querySelector(".mobile-categories ul");

  const categories = [
    { name: "Grinding Machines", link: "./category.html?category=Grinding Machines" },
    { name: "Lathes", link: "./category.html?category=Lathes" },
    { name: "CNC Lathes", link: "./category.html?category=CNC Lathes" },
    { name: "Vertical Turning Lathes", link: "./category.html?category=Vertical Turning Lathes" },
    { name: "Plano millers", link: "./category.html?category=Plano millers" },
    { name: "Retrofitted Machines (Lathes, Vertical Turning Lathes)", link: "./category.html?category=Retrofitted Machines (Lathes, Vertical Turning Lathes)" },
    { name: "Milling and Boring Machines", link: "./category.html?category=Milling and Boring Machines" },
    { name: "Gear Hobbing/Grinding Machines", link: "./category.html?category=Gear Hobbing/Grinding Machines" },
    { name: "Floor Plates and Rotary Tables", link: "./category.html?category=Floor Plates and Rotary Tables" },
    { name: "Other Machines", link: "./category.html?category=Other Machines" },
    { name: "Forging hammer", link: "./category.html?category=Forging hammer" },
    { name: "Floor Plates", link: "./category.html?category=Floor Plates" },
  ];

  // ✅ Filter out categories that have no products
  const filteredCategories = categories.filter((category) => {
    if (category.subcategories) {
      // ✅ If category has subcategories, keep it only if at least one subcategory has products
      category.subcategories = category.subcategories.filter((sub) => productsByCategory[sub.name]?.length > 0);
      return category.subcategories.length > 0;
    } else {
      // ✅ Otherwise, check if the category itself has products
      return productsByCategory[category.name]?.length > 0;
    }
  });

  // ✅ Generate category HTML
  const generateCategoryHTML = (categoryList) => {
    return categoryList
      .map((category) => {
        if (category.subcategories) {
          return `
            <li class="custom-category-item">
              <span class="fw-bold">${category.name}</span>
              <ul class="ms-3">
                ${category.subcategories.map((sub) => `<li><a href="${sub.link}" class="text-decoration-none fw-bold">${sub.name}</a></li>`).join("")}
              </ul>
            </li>`;
        }
        return `<li><a href="${category.link}" class="text-decoration-none fw-bold">${category.name}</a></li>`;
      })
      .join("");
  };

  // ✅ Update desktop and mobile menus
  if (desktopMenuFirstColumn && desktopMenuSecondColumn) {
    const half = Math.ceil(filteredCategories.length / 2);
    desktopMenuFirstColumn.innerHTML = generateCategoryHTML(filteredCategories.slice(0, half));
    desktopMenuSecondColumn.innerHTML = generateCategoryHTML(filteredCategories.slice(half));
  }

  if (mobileMenu) {
    mobileMenu.innerHTML = generateCategoryHTML(filteredCategories);
  }
}

function renderCategoryCards() {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryName = urlParams.get("category");

  if (!categoryName || !productsByCategory[categoryName]) {
    document.querySelector(".container").innerHTML = `
      <h2 class="text-center mt-5">No products found for this category.</h2>
    `;
    return;
  }

  const categoryProducts = productsByCategory[categoryName];
  const cardContainer = document.querySelector(".container"); // Target the correct container

  cardContainer.innerHTML = `
    <div class="row">
      ${categoryProducts
        .map(
          (product) => `
        <div class="col-lg-3 col-md-4 col-sm-6">
          <div class="card border-0 hover-card product-card" data-category="${categoryName}" data-product="${product.title}">
            <img src="${product.images[0]}" class="card-img-top" alt="${product.title}">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">${product.title}</h5>
              <p class="card-text">${product.description.substring(0, 100)}...</p>
              <div class="mt-auto d-flex justify-content-end">
                <i class="bi bi-arrow-right arrow-link"></i>
              </div>
            </div>
          </div>
        </div>
      `
        )
        .join("")}
    </div>
  `;

  // ✅ Add event listeners to all cards (make them clickable)
  document.querySelectorAll(".product-card").forEach((card) => {
    card.addEventListener("click", function () {
      const category = this.getAttribute("data-category");
      const product = this.getAttribute("data-product");
      window.location.href = `product.html?category=${encodeURIComponent(category)}&product=${encodeURIComponent(product)}`;
    });
  });
}

function renderProductPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const productName = urlParams.get("product");
  const categoryName = urlParams.get("category");

  if (!categoryName || !productsByCategory[categoryName]) return;

  const product = productsByCategory[categoryName].find((item) => item.title === productName);

  if (!product) return;

  // ✅ Inject product details
  document.getElementById("machine-title").textContent = product.title;
  document.getElementById("machine-info").innerHTML = `Year: ${product.year} | Location: ${product.location}`;
  document.getElementById("machine-details").innerHTML = product.description.replace(/\n/g, "<br>");

  // ✅ Dynamically Render Carousel Images (Only if images exist)
  const carouselInner = document.querySelector(".carousel-inner");
  const thumbnailsContainer = document.querySelector(".image-thumbnails");

  if (product.images && product.images.length > 0) {
    carouselInner.innerHTML = product.images
      .map(
        (img, index) => `
        <div class="carousel-item ${index === 0 ? "active" : ""}">
          <img src="${img}" class="d-block w-100" alt="${product.title} Image ${index + 1}">
        </div>
      `
      )
      .join("");

    // ✅ Generate Thumbnails
    thumbnailsContainer.innerHTML = product.images
      .map(
        (img, index) => `
        <img src="${img}" class="img-thumbnail mx-2 thumbnail-img" data-index="${index}" alt="Thumbnail ${index + 1}">
      `
      )
      .join("");

    // ✅ Add Event Listener for Thumbnails
    document.querySelectorAll(".thumbnail-img").forEach((thumbnail) => {
      thumbnail.addEventListener("click", function () {
        const index = this.getAttribute("data-index");
        const carousel = new bootstrap.Carousel(document.getElementById("machineCarousel"));
        carousel.to(index);

        // ✅ Highlight the active thumbnail
        document.querySelectorAll(".thumbnail-img").forEach((img) => img.classList.remove("active"));
        this.classList.add("active");
      });
    });
  } else {
    // ✅ If no images exist, clear the elements
    carouselInner.innerHTML = "";
    thumbnailsContainer.innerHTML = "";
  }

  // ✅ Add event listener to "Contact Us" button
  const contactButton = document.getElementById("contact-button");
  if (contactButton) {
    contactButton.addEventListener("click", () => {
      window.location.href = `contact.html?product=${encodeURIComponent(product.title)}`;
    });
  }
}

function handleMobileMenu() {
  const mobileLinks = document.querySelectorAll(".mobile-link");
  const offcanvasMenu = document.querySelector("#offcanvasNav");

  mobileLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      const href = link.getAttribute("href");

      // ✅ If the link is a section (e.g., #inventory, #about-us)
      if (href.startsWith("#")) {
        event.preventDefault();

        // ✅ Close the Offcanvas Menu Immediately
        const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasMenu);
        if (offcanvasInstance) {
          offcanvasInstance.hide(); // ✅ Force close the menu
        }

        // ✅ Scroll smoothly to the section
        const targetSection = document.querySelector(href);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    });
  });

  // ✅ Close Offcanvas When Clicking Outside of It
  document.addEventListener("click", (event) => {
    if (!offcanvasMenu.contains(event.target) && !event.target.closest(".navbar-toggler")) {
      const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasMenu);
      if (offcanvasInstance) {
        offcanvasInstance.hide();
      }
    }
  });
}

// Initialize Everything

document.addEventListener("DOMContentLoaded", () => {
  const topInfo = document.querySelector(".top-info");

  window.addEventListener("scroll", () => {
    if (window.scrollY > 50) {
      // Adjust the value as needed
      topInfo.classList.add("sticky"); // Adds the sticky class
    } else {
      topInfo.classList.remove("sticky"); // Removes the sticky class
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const contactButton = document.querySelector(".floating-contact-btn");

  gsap.registerPlugin(ScrollTrigger);

  // ✅ Ensure the button is ALWAYS fixed and visible
  gsap.set(contactButton, { opacity: 1, y: 0, pointerEvents: "all", position: "fixed", bottom: "20px" });
});

document.addEventListener("DOMContentLoaded", () => {
  const urlParams = new URLSearchParams(window.location.search);
  const productName = urlParams.get("product");
  const messageField = document.getElementById("message");

  if (productName && messageField) {
    messageField.value = `I am interested in ${productName}. 
    Please provide more details.`.replace(/\n+/g, " ");
  }
});

document.addEventListener("DOMContentLoaded", () => {
  let pageTitle = "Delta Machine"; // Default title (Company Name)

  if (window.location.pathname.includes("index.html")) {
    pageTitle = "Home | Delta Machine";
  } else if (window.location.pathname.includes("contact.html")) {
    pageTitle = "Contact Us | Delta Machine";
  } else if (window.location.pathname.includes("inventory.html")) {
    pageTitle = "Available Machines | Delta Machine";
  } else if (window.location.pathname.includes("category.html")) {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryName = urlParams.get("category");
    pageTitle = categoryName ? `${categoryName} | Delta Machine` : "Categories | Delta Machine";
  } else if (window.location.pathname.includes("product.html")) {
    const urlParams = new URLSearchParams(window.location.search);
    const productName = urlParams.get("product");
    pageTitle = productName ? `${productName} | Delta Machine` : "Product Details | Delta Machine";
  }

  document.title = pageTitle; // ✅ Set the dynamic title
});

document.addEventListener("DOMContentLoaded", () => {
  renderCategories();
  updateBreadcrumb();
  handleMobileMenu();

  if (window.location.pathname.includes("category.html")) {
    renderCategoryCards(); // Render product cards on the category page
  } else if (window.location.pathname.includes("product.html")) {
    renderProductPage(); // Render product details
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const contactForm = document.getElementById("contact-form");
  const thankYouMessage = document.getElementById("thank-you-message");

  if (!contactForm) return;

  contactForm.addEventListener("submit", function (event) {
    event.preventDefault();

    emailjs
      .sendForm("service_kkyppas", "template_k714k0d", contactForm, "oJe0auv7HRWoq3Lmn")
      .then(() => {
        contactForm.style.display = "none";
        if (thankYouMessage) thankYouMessage.style.display = "block";

        setTimeout(() => {
          window.location.href = "index.html";
        }, 5000);
      })
      .catch((error) => {
        console.error("EmailJS error:", error);
        alert("Something went wrong. Please try again.");
      });
  });
});
